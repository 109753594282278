import { buffer, getCenter } from 'ol/extent';
import { load } from 'ol/Image.js';
import ImageLayer from 'ol/layer/Image';
import Projection from 'ol/proj/Projection';
import ImageSource from 'ol/source/Image';
import { createLoader } from 'ol/source/static';
import View from 'ol/View';

import { type IMapBase } from './OLMap.client';

export const makeMapBase = ({ mapBase, padding }: { mapBase: IMapBase; padding?: number[] }) => {
  const projection = new Projection({
    code: 'custom-image',
    units: 'm',
    extent: mapBase.extent,
    metersPerUnit: 1,
    global: false,
    worldExtent: mapBase.worldExtent,
    getPointResolution: (resolution) => resolution,
  });

  const source = new ImageSource({
    loader: createLoader({
      url: mapBase.url,
      load: load,
      imageExtent: mapBase.worldExtent,
    }),
    projection,
  });

  const layer = new ImageLayer({
    visible: true,
    source,
    zIndex: 0,
  });

  const view = new View({
    projection,
    center: getCenter(mapBase.worldExtent),
    extent: buffer(mapBase.worldExtent, 500 * mapBase.resolution), // extend by 500 pixels
    showFullExtent: true,
    resolution: mapBase.resolution,
    minResolution: mapBase.minResolution, // max zoom
    maxResolution: mapBase.maxResolution, // min zoom
    padding,
    rotation: mapBase.rotation ? -mapBase.rotation : undefined,
  });

  return {
    layer,
    projection,
    source,
    view,
  };
};
