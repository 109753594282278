import type { FeatureCollection } from 'geojson';
import type { SimpleGeometry } from 'ol/geom';

import { Outlet } from '@remix-run/react';
import { useRef } from 'react';

import { type Extent } from 'ol/extent';
// import { defaults as defaultInteractions } from 'ol/interaction/defaults';
// import LinkInteraction from 'ol/interaction/Link';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import OL from 'ol/Map';

import {
  ControlsBox,
  LayersOptions,
  MapComponent,
  MapProvider,
  Zoom,
  MapDrawer,
  useLogisticPlaces,
  LogisticPlaces,
  useFetcherLogisticPlaces } from
'@/lib/OpenLayers';

import classes from './map.module.css';
import { makeMapBase } from './utils';

const mapGeoJson = new GeoJSON({
  dataProjection: undefined,
  featureClass: Feature
});

export interface IMapBase {
  url: string;
  extent: Extent;
  worldExtent: Extent;
  resolution: number;
  minResolution: number;
  maxResolution: number;
  rotation?: number;
}

export interface OLMapProps {
  mapBase: IMapBase;
  logisticPlaces: FeatureCollection;
}

export const OLMap = (props: OLMapProps): JSX.Element => {
  const { mapBase, logisticPlaces } = props;

  const MAP_TARGET = 'map-component';
  const mapDrawerWidth = 350;
  const padding = [0, 0, 0, mapDrawerWidth];

  const mapBaseRef = useRef(makeMapBase({ mapBase, padding }));

  const { source: logisticPlacesSource, layer: logisticLayer } = useLogisticPlaces({
    sourceOpts: {
      features: (mapGeoJson.readFeatures(logisticPlaces) as Feature<SimpleGeometry>[])
    },
    layerOpts: {
      zIndex: 100,
      visible: globalThis.ENV.ENABLE_LOGISTIC_PLACES === 'true'
    }
  });

  const mapRef = useRef(
    new OL({
      controls: [],
      // interactions: defaultInteractions().extend([new LinkInteraction({ params: ['x', 'y', 'z', 'l'] })]),
      layers: [mapBaseRef.current.layer, logisticLayer],
      view: mapBaseRef.current.view
    })
  );

  const { load: placesLoad, values: logisticPlacesFilterValues } = useFetcherLogisticPlaces({
    logisticPlacesSource
  });

  return (
    <MapProvider
      value={{
        map: mapRef.current,
        mapTargetId: MAP_TARGET
      }}>

      <MapDrawer className={classes.drawerPaper} w={mapDrawerWidth}>
        <Outlet />
      </MapDrawer>
      <MapComponent id={MAP_TARGET} className={classes.mapComponent}>
        <ControlsBox id='map-controls-top-left' align='top-left' direction='row' left={mapDrawerWidth + 16} />
        <ControlsBox id='map-controls-bottom-right' align='bottom-right'>
          {globalThis.ENV.ENABLE_LOGISTIC_PLACES === 'true' &&
          <LayersOptions>
              <LogisticPlaces value={logisticPlacesFilterValues} onChange={(value) => placesLoad(value)} />
            </LayersOptions>}

          <Zoom />
        </ControlsBox>
      </MapComponent>
    </MapProvider>);

};