import olStyles from 'ol/ol.css';
import olExtStyles from 'ol-ext/dist/ol-ext.css';

import { type LinksFunction, type LoaderFunctionArgs, type MetaFunction, json } from '@remix-run/node';
import { useLoaderData, type ShouldRevalidateFunction } from '@remix-run/react';

import { Box } from '@mantine/core';

import { getLogisticPlacesList, getMapMetaData, type IReadLogisticPlaceList } from '@/api/map.server';
import { ClientOnly } from '@/components/ClientOnly';
import { GeneralErrorBoundary } from '@/components/ErrorBoundary';
import { i18nRemix } from '@/i18n/i18next.server';
import { TOKEN } from '@/utils/auth.server';

import classes from './map.module.css';
import { OLMap } from './OLMap.client';

const emptyLogisticPlaces: IReadLogisticPlaceList = { type: 'FeatureCollection', features: [] };

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const t = await i18nRemix.getFixedT(request);

  const [mapMetaData, logisticPlaces] = await Promise.all([
  getMapMetaData(),
  process.env.ENABLE_LOGISTIC_PLACES === 'true' ? getLogisticPlacesList() : Promise.resolve(emptyLogisticPlaces)]
  );

  const { height, originX, originY, resolution, width, rotation } = mapMetaData;
  const widthInMeters = width * resolution;
  const heightInMeters = height * resolution;

  return json({
    title: t('mapRoute.title'),
    description: t('mapRoute.description'),
    hallBase: {
      url: `${process.env.API_URL}/map/image?ticket=${TOKEN}`,
      extent: [0, 0, widthInMeters, heightInMeters],
      worldExtent: [0 + originX, 0 + originY, widthInMeters + originX, heightInMeters + originY],
      resolution,
      minResolution: resolution / Math.pow(2, 8), // max zoom
      maxResolution: resolution * 2, // min zoom
      rotation
    },
    logisticPlaces
  });
};

export const shouldRevalidate: ShouldRevalidateFunction = () => false;

export const meta: MetaFunction<typeof loader> = ({ error, data }) => {
  return [{ title: error ? 'Oops!' : data?.title }, { name: 'description', content: data?.description }];
};

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: olStyles },
{ rel: 'stylesheet', href: olExtStyles }];


export default function Index() {
  const { hallBase, logisticPlaces } = useLoaderData<typeof loader>();

  return (
    <Box className={classes.mapContainer}>
      <ClientOnly>{() => <OLMap mapBase={hallBase} logisticPlaces={logisticPlaces} />}</ClientOnly>
    </Box>);

}

export const ErrorBoundary = GeneralErrorBoundary;